<template>
  <collective-detail
    breadcrumb-route="organizations"
    item-type="organizations"
    item-type-single="organization"
  />
</template>

<script>
import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'OrganizationsDetails',
  components: {
    CollectiveDetail,
  },
};
</script>
